import SelectMonth from "./components/select-month";
import {InformationCircle} from "../../../components/primitives/icons/InformationCircle";
import {useAppSelector} from "../../../_store/hooks";
import Report from "./components/report";
import {useParams} from "react-router-dom";
import useCurrentUserRole from "../../../components/hooks/useCurrentUserRole";

export function InfluencerReports() {
	const {loading, data} = useAppSelector(state => state.reports);
	const {id} = useParams<{id: string; name: string}>();
	const {activeRole: role} = useCurrentUserRole();

	return (
		<>
			<section className="sticky top-0 z-10 flex items-center justify-between border-b border-b-border bg-background p-6 py-4">
				<h2 className="flex-1 scroll-m-20 text-3xl font-extrabold tracking-tight">Reportes</h2>
				<div className="flex items-center gap-2">
					<SelectMonth onChangeMonth={console.log} partner={id} />
				</div>
			</section>
			<section className="flex flex-col p-6 py-4">
				{loading ? <LoadingReport /> : <Report data={data} isCorporate={role === "corporate"} partner={id} />}
			</section>
		</>
	);
}

const LoadingReport = () => (
	<div className="mx-auto my-10 flex h-full max-w-lg flex-col items-center justify-center text-center">
		<p className="mb-4 rounded-full p-3 text-sm font-medium text-secondary">
			<InformationCircle className="h-10 w-10 text-black" />
		</p>
		<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-4xl">Cargando...</h1>
	</div>
);
