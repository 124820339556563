import {DateRange} from "react-day-picker";
import {Card} from "../../../../../components/blocks/Stats/Card";
import {
	useGetInfluencerDataQuery,
	useGetInfluencerPerformanceQuery,
	useGetInfluencerRegistrationQuery,
	useGetInfluencerRevenueQuery,
} from "../../../../../_store/features/influencer/api";
import {formatISO9075} from "date-fns";

interface IncomeInfluencerCardProps {
	dates: DateRange;
}

const IncomeInfluencerCard = ({dates}: IncomeInfluencerCardProps) => {
	const {data, isLoading: isLoadingMetadata} = useGetInfluencerDataQuery({});

	const {data: performance, isLoading: isLoadingPerformance} = useGetInfluencerPerformanceQuery({
		final_date: formatISO9075(dates.to!),
		initial_date: formatISO9075(dates.from!),
	});
	const {data: influencerRevenue, isLoading: isLoadingRevenue} = useGetInfluencerRevenueQuery({
		final_date: formatISO9075(dates.to!),
		initial_date: formatISO9075(dates.from!),
	});
	const {data: influencerRegistration} = useGetInfluencerRegistrationQuery({
		final_date: formatISO9075(dates.to!),
		initial_date: formatISO9075(dates.from!),
	});

	const isLoading = isLoadingMetadata || isLoadingPerformance || isLoadingRevenue;

	const earningSubscriptions =
		influencerRevenue?.details.reduce((acc, curr) => {
			if (curr.type === 0)
				return (
					acc +
					Number(curr.stripe_price) * (Number(data?.month_subscription_commission ?? 0) / 100.0) +
					Number(curr.stripe_price_renewal) * (Number(data?.month_subscription_commission ?? 0) / 100.0)
				);
			return (
				acc +
				Number(curr.stripe_price) * (Number(data?.annual_subscription_commission ?? 0) / 100.0) +
				Number(curr.stripe_price_renewal) * (Number(data?.annual_subscription_commission ?? 0) / 100.0)
			);
		}, 0) ?? 0;

	const totalRegistrations =
		influencerRegistration?.reduce((acc, curr) => {
			acc += Number(curr.total);
			return acc;
		}, 0) ?? 0;

	const earningPerformance =
		((performance?.total_referral_seconds || 0) / (performance?.total_seconds || 1)) * (influencerRevenue?.royalty_fund || 0);

	return (
		<div className="grid grid-flow-row grid-cols-2 gap-3  xl:grid-cols-4 2xl:grid-cols-5">
			<Card
				title="Ganancias Personales"
				amount={(earningSubscriptions + earningPerformance) / 100}
				tooltip="Estimación de ganancias del periodo basado en las suscripciones de tus referidos y las visualizaciones de su contenido."
				loading={isLoading}
			/>
			<Card
				title="Ingresos por Referidos"
				amount={earningSubscriptions / 100}
				tooltip="Total generado por las compras de suscripciones realizadas por tus referidos en el periodo seleccionado."
				loading={isLoading}
			/>
			<Card
				title="Ingresos por Visualizaciones"
				amount={earningPerformance / 100}
				tooltip="Total generado por las visualizaciones de contenido realizadas por tus referidos."
				loading={isLoading}
			/>
			<Card
				title="Usuarios referidos"
				tooltip="Usuarios registrados en el período seleccionado."
				loading={isLoading}
				amount={totalRegistrations}
				isMoney={false}
			/>
		</div>
	);
};

export default IncomeInfluencerCard;
