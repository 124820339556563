import {InformationCircle} from "../../../../components/primitives/icons/InformationCircle";
import {ReportResponse} from "../../../../data-access/report/types";

interface ReportProps {
	data: ReportResponse | undefined | null;
	isCorporate?: boolean;
	partner?: string;
}

const Report = (props: ReportProps) => {
	return <NoReportAvailable {...props} />;
};

interface NoReportAvailableProps {
	isCorporate?: boolean;
	partner?: string;
}

const NoReportAvailable = (props: NoReportAvailableProps) => {
	const text = props.isCorporate
		? `No se ha generado un reporte aún`
		: `Por favor contacta con un administrador o espera a ser añadido el reporte solicitado.`;

	return (
		<div className="mx-auto my-10 flex h-full max-w-lg flex-col items-center justify-center text-center">
			<p className="mb-4 rounded-full p-3 text-sm font-medium text-secondary">
				<InformationCircle className="h-10 w-10 text-black" />
			</p>
			<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-4xl">Reporte no disponible aún.</h1>
			<p className="mb-4 leading-7 [&:not(:first-child)]:mt-6">{text}</p>
		</div>
	);
};

export default Report;
