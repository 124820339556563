import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {createAppAsyncThunk} from "../../hooks";
import {auth} from "../../../firebase";
import {
	getSerieIncomeAndProfit,
	getSeriesLikesAndFavorites,
	getContentsLikesAndFavorites,
	getSponsorAdViewsData,
	gGetInfluencerProfitData,
	getSponsorAdViewsByAdsData,
	getLatestTransactionsDetails,
	getGeneralIncomeAndProfit,
	getSeriesContentEarnings,
	getInfluencerProfit,
	getProfitCorporateListData,
	getUsersVerificationData,
	getUsersActiveData,
	getSponsorWorldViewsData,
	getDailyVerificationsData,
	getUsersDemographicData,
	getAppInstallationsData,
	getAppInstallationsByLocationData,
	getSubscribersLocationData,
	getAssignedFreeSubscriptionsData,
	getRentalsLocationsData,
	GetRentalsLocationsDataResponse,
	listPlatformCostData,
	editPlatformCostData,
	editCostConceptData,
	listCostConceptData,
	insertCostConceptData,
	getCostAndEarningsData,
	insertPlatformCostData,
	getSerieIncomeAndProfitByRent,
} from "../../../data-access/dashboard/statistics";
import {
	GetSerieIncomeAndProfitParams,
	GetSerieIncomeAndProfitResponse,
	GetSerieLikesAndFavoritesParams,
	GetContentLikesAndFavoritesParams,
	GetSponsorAdViewResponse,
	GetSponsorAdViewParams,
	GetInfluencerProfitParams,
	GetInfluencerProfitResponse,
	GetInfluencerAndPartnerTransactionsResponse,
	GetInfluencerAndPartnerTransactionsParams,
	GetGeneralIncomeAndProfitResponse,
	GetSerieContentEarnings,
	SeriesContentEarningsResponse,
	GetGeneralIncomeAndProfitParams,
	InfluencersBySeriesProfitParams,
	GetProfitCorporateParams,
	GetProfitCorporateResponse,
	GetSeriesAndContentInteractionResponse,
	GetUsersVerificationData,
	GetUsersVerificationDataParams,
	GetActiveUsersResponse,
	GetActiveUsersParams,
	GetSponsorWorldViewsResponse,
	GetSponsorWorldViewsParams,
	GetDailyVerificationsDataResponse,
	GetDailyVerificationsDataParams,
	GetUsersDemographicDataResponse,
	GetUsersDemographicDataParams,
	GetAppInstallationsDataResponse,
	GetAppInstallationsDataParams,
	GetAppConsumptionByLocation,
	GetAppInstallationsByLocationParams,
	GetSubscribersLocationDataResponse,
	GetAssignedFreeSubscriptionsResponse,
	GetAssignedFreeSubscriptionsParams,
	InfluencersListProfitResponse,
	ListPlatformCostDataParams,
	GetPlatformCostDataResponse,
	EditPlatformCostDataParams,
	EditCostConceptData,
	ListCostConceptDataParams,
	GetCostAndEarningsDataParams,
	GetCostAndEarningsDataResponse,
	InsertPlatformCostData,
	GetSerieIncomeAndProfitByRentalParams,
	GetSerieIncomeAndProfitByRentalResponse,
} from "../../../data-access/dashboard/types";
import {RootState} from "../../store";
import {
	GetMuxDataViewPercentsParams,
	GetMuxDataViewsPercentResponse,
	getMuxDataViewsPercents,
	getReproductionTimeData,
	GetReproductionTimeDataParams,
	GetReproductionTimeDataResponse,
	getTopContent,
	getTopSeries as getTopSeriesData,
} from "../../../data-access/mux";
import {TopStatsResponse} from "../../../data-access/mux";
import {LocationInterface} from "../../../pages/corporate-pages/users/_subcomponents/AppConsuptionWorldMap";

type RequestState = "pending" | "fulfilled" | "rejected";
export type DashboardQueries =
	| "generalIncome"
	| "partnerSeriesIncome"
	| "topSeries"
	| "serieLastMonthLikesAndFav"
	| "contentLastMonthLikesAndFav"
	| "topSeriesContents"
	| "sponsorAdViews"
	| "sponsorAdViewsByAds"
	| "seriesIncome"
	| "influencerIncome"
	| "latestTransactions"
	| "profitCorporate"
	| "serieContentEarning"
	| "influencerProfitByContent"
	| "usersVerificationData"
	| "usersActiveData"
	| "sponsorWorldViews"
	| "usersDailyVerificationsData"
	| "usersDemographicData"
	| "appInstallationsData"
	| "appConsumptionByLocation"
	| "subscribersLocations"
	| "rentalsLocations"
	| "freeSubscriptionsByUsers"
	| "getReproductionTime"
	| "getMuxDataViewsPercents"
	| "listPlatformCosts"
	| "getCostAndEarnings"
	| "rentalsIncomeEarning";

export type DataByName = {
	generalIncome: GetGeneralIncomeAndProfitResponse;
	partnerSeriesIncome: GetSerieIncomeAndProfitResponse;
	serieLastMonthLikesAndFav: GetSeriesAndContentInteractionResponse;
	topSeries: TopStatsResponse;
	contentLastMonthLikesAndFav: GetSeriesAndContentInteractionResponse;
	topSeriesContents: TopStatsResponse;
	sponsorAdViews: GetSponsorAdViewResponse;
	sponsorAdViewsByAds: GetSponsorAdViewResponse;
	seriesIncome: Record<string, GetSerieIncomeAndProfitResponse>;
	influencerIncome: GetInfluencerProfitResponse;
	latestTransactions: GetInfluencerAndPartnerTransactionsResponse[];
	profitCorporate: GetProfitCorporateResponse;
	serieContentEarning: SeriesContentEarningsResponse[];
	influencerProfitByContent: InfluencersListProfitResponse;
	usersVerificationData: GetUsersVerificationData[];
	usersActiveData: GetActiveUsersResponse;
	sponsorWorldViews: GetSponsorWorldViewsResponse;
	usersDailyVerificationsData: GetDailyVerificationsDataResponse;
	usersDemographicData: GetUsersDemographicDataResponse[];
	appInstallationsData: GetAppInstallationsDataResponse;
	appConsumptionByLocation: GetAppConsumptionByLocation;
	subscribersLocations: GetSubscribersLocationDataResponse;
	rentalsLocations: GetRentalsLocationsDataResponse[];
	freeSubscriptionsByUsers: GetAssignedFreeSubscriptionsResponse[];
	getReproductionTime: GetReproductionTimeDataResponse;
	getMuxDataViewsPercents: GetMuxDataViewsPercentResponse;
	listPlatformCosts: GetPlatformCostDataResponse;
	getCostAndEarnings: GetCostAndEarningsDataResponse;
	rentalsIncomeEarning: GetSerieIncomeAndProfitByRentalResponse;
};

export interface dashboardInterface {
	loading: boolean;
	selectedSerieId: string | undefined;
	selectedContentId: string | undefined;
	statusByName: Record<DashboardQueries, RequestState | undefined>;
	dataByName: {
		[k in keyof DataByName]: DataByName[k] | undefined;
	};
}

const initialState: dashboardInterface = {
	loading: false,
	selectedSerieId: undefined,
	selectedContentId: undefined,
	statusByName: {
		generalIncome: undefined,
		partnerSeriesIncome: undefined,
		topSeries: undefined,
		serieLastMonthLikesAndFav: undefined,
		contentLastMonthLikesAndFav: undefined,
		topSeriesContents: undefined,
		sponsorAdViews: undefined,
		sponsorAdViewsByAds: undefined,
		seriesIncome: undefined,
		influencerIncome: undefined,
		latestTransactions: undefined,
		profitCorporate: undefined,
		serieContentEarning: undefined,
		influencerProfitByContent: undefined,
		usersVerificationData: undefined,
		usersActiveData: undefined,
		sponsorWorldViews: undefined,
		usersDailyVerificationsData: undefined,
		usersDemographicData: undefined,
		appInstallationsData: undefined,
		appConsumptionByLocation: undefined,
		subscribersLocations: undefined,
		rentalsLocations: undefined,
		freeSubscriptionsByUsers: undefined,
		getReproductionTime: undefined,
		getMuxDataViewsPercents: undefined,
		listPlatformCosts: undefined,
		getCostAndEarnings: undefined,
		rentalsIncomeEarning: undefined,
	},
	dataByName: {
		generalIncome: undefined,
		partnerSeriesIncome: undefined,
		topSeries: undefined,
		serieLastMonthLikesAndFav: undefined,
		contentLastMonthLikesAndFav: undefined,
		topSeriesContents: undefined,
		sponsorAdViews: undefined,
		sponsorAdViewsByAds: undefined,
		seriesIncome: undefined,
		influencerIncome: undefined,
		latestTransactions: undefined,
		profitCorporate: undefined,
		serieContentEarning: undefined,
		influencerProfitByContent: undefined,
		usersVerificationData: undefined,
		usersActiveData: undefined,
		sponsorWorldViews: undefined,
		usersDailyVerificationsData: undefined,
		usersDemographicData: undefined,
		appInstallationsData: undefined,
		appConsumptionByLocation: undefined,
		subscribersLocations: undefined,
		rentalsLocations: undefined,
		freeSubscriptionsByUsers: undefined,
		getReproductionTime: undefined,
		getMuxDataViewsPercents: undefined,
		listPlatformCosts: undefined,
		getCostAndEarnings: undefined,
		rentalsIncomeEarning: undefined,
	},
};

// Deprecated endpoint, before delete it, check usage at get detailed statistics for specific serie-id
// for corporate is not required any more
const getGeneralSeriesIncomeAndProfit = createAppAsyncThunk(
	"dashboard/get-general-income",
	async (params: GetGeneralIncomeAndProfitParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await getGeneralIncomeAndProfit(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getPartnersSeriesProfit = createAppAsyncThunk(
	"dashboard/get-serie-income-last-month",
	async (params: GetSerieIncomeAndProfitParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await getSerieIncomeAndProfit(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getPartnersSeriesProfitByRent = createAppAsyncThunk(
	"dashboard/get-serie-income-by-rent",
	async (params: GetSerieIncomeAndProfitByRentalParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await getSerieIncomeAndProfitByRent(token, params);

			return {...params, data: response.data};
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getPartnerSeriesIncomeAndProfit = createAppAsyncThunk(
	"dashboard/get-series-income",
	async (params: Pick<GetSerieIncomeAndProfitParams, "initial_date" | "final_date">, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const responses = await Promise.all(
				thunkAPI
					.getState()
					.user.userInfo?.get_partner_series?.filter(el => el.active)
					.map(async serie => {
						const res = await getSerieIncomeAndProfit(token, {...params, seriesid_list: serie.serieid});

						return [serie.serieid, res.data];
					}) ?? [],
			);

			return Object.fromEntries(responses);
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getInfluencerSerieIncomeAndProfit = createAppAsyncThunk(
	"dashboard/get-influencer-income",
	async (params: GetInfluencerProfitParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await gGetInfluencerProfitData(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getSerieLastMonthLikeAndFavorite = createAppAsyncThunk(
	"dashboard/get-serie-likes-and-favorites-last-month",
	async (params: GetSerieLikesAndFavoritesParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await getSeriesLikesAndFavorites(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getContentLastMonthLikeAndFavorite = createAppAsyncThunk(
	"dashboard/get-content-likes-and-favorites-last-month",
	async (params: GetContentLikesAndFavoritesParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token) return;

			const response = await getContentsLikesAndFavorites(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getTopSeries = createAppAsyncThunk("dashboard/get-top-series", async (_, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getTopSeriesData(token);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getTopSeriesContents = createAppAsyncThunk("dashboard/get-top-serie-contents", async (seriesid_list: string, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await getTopContent(token, seriesid_list);

		if (!thunkAPI.getState().dashboard.selectedContentId) {
			thunkAPI.dispatch(dashboardActions.setSelectedContentId(response.data[0].contentid));
		}

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getSponsorAdViews = createAppAsyncThunk("dashboard/get-sponsor-ad-views", async (params: GetSponsorAdViewParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();

		if (!token || !auth.currentUser?.uid) return;

		const response = await getSponsorAdViewsData(token, {...params});

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getSponsorAdViewsByAds = createAppAsyncThunk("dashboard/get-sponsor-ad-views-by-ads", async (params: GetSponsorAdViewParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();

		if (!token) return;

		const response = await getSponsorAdViewsByAdsData(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getInfluencerAndPartnerTransactions = createAppAsyncThunk(
	"dashboard/get-influencer-and-partner-transactions",
	async (params: GetInfluencerAndPartnerTransactionsParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();

			if (!token || !auth.currentUser?.uid) return;

			const response = await getLatestTransactionsDetails(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getProfitCorporateTransactions = createAppAsyncThunk("dashboard/get-profit-corporate", async (params: GetProfitCorporateParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();

		if (!token || !auth.currentUser?.uid) return;

		const response = await getProfitCorporateListData(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getSerieEarnings = createAppAsyncThunk("dashboard/get-serie-content-earnings", async (params: GetSerieContentEarnings, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();

		if (!token || !auth.currentUser?.uid) return;

		const response = await getSeriesContentEarnings(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const getInfluencerProfitByContent = createAppAsyncThunk(
	"dashboard/get-influencer-profit",
	async (params: InfluencersBySeriesProfitParams | undefined, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();

			if (!token || !auth.currentUser?.uid) return;

			const response = await getInfluencerProfit(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching contents");
		}
	},
);

const getUsersVerificationDataAction = createAppAsyncThunk(
	"dashboard/get-users-verifications-data",
	async (params: GetUsersVerificationDataParams | undefined, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();

			if (!token || !auth.currentUser?.uid) return;

			const response = await getUsersVerificationData(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching users data");
		}
	},
);

const getUsersActiveDataAction = createAppAsyncThunk(
	"dashboard/get-daily-active-users",
	async (params: GetActiveUsersParams | undefined, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();

			if (!token || !auth.currentUser?.uid) return;

			const response = await getUsersActiveData(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching users data");
		}
	},
);

const getSponsorWorldViewsAction = createAppAsyncThunk(
	"dashboard/get-sponsor-world-views",
	async (params: GetSponsorWorldViewsParams | undefined, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();

			if (!token || !auth.currentUser?.uid) return;

			const response = await getSponsorWorldViewsData(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching sponsor content views");
		}
	},
);

const getUsersDailyVerificationDataAction = createAppAsyncThunk(
	"dashboard/get-daily-verification-data",
	async (params: GetDailyVerificationsDataParams | undefined, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();

			if (!token || !auth.currentUser?.uid) return;

			const response = await getDailyVerificationsData(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem fetching users data");
		}
	},
);

const getAllUsersDemographicData = createAppAsyncThunk(
	"dashboard/get-all-users-demographic-data",
	async (params: GetUsersDemographicDataParams | undefined, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();

			if (!token || !auth.currentUser?.uid) return;

			const response = await getUsersDemographicData(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem getting users demographic data");
		}
	},
);

const getAppInstallations = createAppAsyncThunk(
	"dashboard/get-app-installations-data",
	async (params: GetAppInstallationsDataParams | undefined, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();

			if (!token || !auth.currentUser?.uid) return;

			const response = await getAppInstallationsData(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem getting app installations data");
		}
	},
);

const getAppConsumptionByLocation = createAppAsyncThunk(
	"dashboard/get-app-installations-by-location",
	async (params: GetAppInstallationsByLocationParams | undefined, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();

			if (!token || !auth.currentUser?.uid) return;

			const response = await getAppInstallationsByLocationData(token, params);

			let groupedData: GetAppConsumptionByLocation = {global_installations_by_location: [], us_installations_by_location: []};
			let cities: string[] = [];
			let states: string[] = [];
			let countries: string[] = [];

			response.data.installations_by_location.forEach(item => {
				if (!item.latitude || !item.longitude) return;
				const locationSplited: string[] = item.location?.split(",") ?? [];
				const country = locationSplited[locationSplited.length - 1].trim();
				const state = locationSplited[locationSplited.length - 2]?.trim();
				const city = locationSplited[locationSplited.length - 3]?.trim();
				if (country.includes("United States")) {
					groupedData.us_installations_by_location.push(item as LocationInterface);
					if (countries.includes(country) && states.includes(state) && cities.includes(city)) {
						groupedData.global_installations_by_location.find(groupedItem => {
							if (item.location?.includes(state) && item.location.includes(country) && item.location.includes(city)) {
								groupedItem.view_count = groupedItem.view_count + item.view_count;
								return true;
							}
						});
					} else {
						if (city) cities.push(city);
						if (state) states.push(state);
						if (country) countries.push(country);
						groupedData.global_installations_by_location.push(item as LocationInterface);
					}
				} else {
					if (countries.includes(country)) {
						groupedData.global_installations_by_location.find(groupedItem => {
							if (item.location.includes(country)) {
								groupedItem.view_count = groupedItem.view_count + item.view_count;
							}
						});
					} else {
						if (country) countries.push(country);
						groupedData.global_installations_by_location.push(item as LocationInterface);
					}
				}
			});
			return groupedData;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem getting app installations by location data");
		}
	},
);

const getSubscribersLocations = createAppAsyncThunk("dashboard/get-subscribers-locations", async (params: undefined, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();

		if (!token || !auth.currentUser?.uid) return;

		const response = await getSubscribersLocationData(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem getting subscribers locations data");
	}
});

const getRentalsLocations = createAppAsyncThunk("dashboard/get-rentals-locations", async (params: undefined, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();

		if (!token || !auth.currentUser?.uid) return;

		const response = await getRentalsLocationsData(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem getting rentals locations data");
	}
});

const getAssignedFreeSubscriptionsByUser = createAppAsyncThunk(
	"dashboard/get-assigned-free-subscriptions-by-user",
	async (params: GetAssignedFreeSubscriptionsParams | undefined, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();

			if (!token || !auth.currentUser?.uid) return;

			const response = await getAssignedFreeSubscriptionsData(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem getting free subscriptions data");
		}
	},
);

const getReproductionTime = createAppAsyncThunk(
	"dashboard/get-reproduction-time",
	async (params: GetReproductionTimeDataParams | undefined, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();

			if (!token || !auth.currentUser?.uid) return;

			const response = await getReproductionTimeData(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem getting reproduction time data");
		}
	},
);

const getMuxDataViewsPercentsAction = createAppAsyncThunk(
	"dashboard/get-mux-data-views-percents",
	async (params: GetMuxDataViewPercentsParams | undefined, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token || !auth.currentUser?.uid) return;
			const response = await getMuxDataViewsPercents(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem getting mux data views percents time data");
		}
	},
);

const listPlatformCostsAction = createAppAsyncThunk(
	"dashboard/list-platform-costs",
	async (params: ListPlatformCostDataParams | undefined, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token || !auth.currentUser?.uid) return;
			const response = await listPlatformCostData(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem getting platform costs data");
		}
	},
);

const editPlatformCostDataAction = createAppAsyncThunk(
	"dashboard/edit-platform-cost",
	async (params: EditPlatformCostDataParams | undefined, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token || !auth.currentUser?.uid) return;
			const response = await editPlatformCostData(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem editing platform costs data");
		}
	},
);

const editCostConceptDataAction = createAppAsyncThunk("dashboard/edit-cost-concept", async (params: EditCostConceptData | undefined, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token || !auth.currentUser?.uid) return;
		const response = await editCostConceptData(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem editing platform costs concept data");
	}
});

const listCostConceptDataAction = createAppAsyncThunk(
	"dashboard/list-cost-concept",
	async (params: ListCostConceptDataParams | undefined, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token || !auth.currentUser?.uid) return;
			const response = await listCostConceptData(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem getting platform costs concept data");
		}
	},
);

const insertCostConceptDataAction = createAppAsyncThunk("dashboard/insert-cost-concept", async (description: string, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token || !auth.currentUser?.uid) return;
		const response = await insertCostConceptData(token, description);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem inserting platform costs concept data");
	}
});

const insertPlatformCostDataAction = createAppAsyncThunk("dashboard/insert-platform-cost", async (data: InsertPlatformCostData, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token || !auth.currentUser?.uid) return;
		const response = await insertPlatformCostData(token, data);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem inserting platform costs data");
	}
});

const getCostAndEarningsDataAction = createAppAsyncThunk(
	"dashboard/get-cost-earnings-data",
	async (params: GetCostAndEarningsDataParams, thunkAPI) => {
		try {
			const token = await auth.currentUser?.getIdToken();
			if (!token || !auth.currentUser?.uid) return;
			const response = await getCostAndEarningsData(token, params);

			return response.data;
		} catch (error: any) {
			console.log(error);
			return thunkAPI.rejectWithValue("Problem getting serie content earnings data");
		}
	},
);

const dashboardSlice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setSelectedSerieId(state, action: PayloadAction<string | undefined>) {
			state.selectedSerieId = action.payload;
		},
		setSelectedContentId(state, action: PayloadAction<string | undefined>) {
			state.selectedContentId = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getGeneralSeriesIncomeAndProfit.pending, (state, action) => {
				state.loading = true;
				state.statusByName["generalIncome"] = "pending";
			})
			.addCase(getGeneralSeriesIncomeAndProfit.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["generalIncome"] = "fulfilled";
				state.dataByName["generalIncome"] = action.payload;
			})
			.addCase(getGeneralSeriesIncomeAndProfit.rejected, (state, action) => {
				state.statusByName["generalIncome"] = "rejected";
			});
		builder
			.addCase(getPartnersSeriesProfit.pending, (state, action) => {
				state.loading = true;
				state.statusByName["partnerSeriesIncome"] = "pending";
			})
			.addCase(getPartnersSeriesProfit.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["partnerSeriesIncome"] = "fulfilled";
				state.dataByName["partnerSeriesIncome"] = action.payload;
			})
			.addCase(getPartnersSeriesProfit.rejected, (state, action) => {
				state.statusByName["partnerSeriesIncome"] = "rejected";
			});
		builder
			.addCase(getTopSeries.pending, (state, action) => {
				state.statusByName["topSeries"] = "pending";
			})
			.addCase(getTopSeries.fulfilled, (state, action) => {
				state.statusByName["topSeries"] = "fulfilled";
				state.dataByName["topSeries"] = action.payload;
			})
			.addCase(getTopSeries.rejected, (state, action) => {
				state.statusByName["topSeries"] = "rejected";
			});
		builder
			.addCase(getTopSeriesContents.pending, (state, action) => {
				state.statusByName["topSeriesContents"] = "pending";
			})
			.addCase(getTopSeriesContents.fulfilled, (state, action) => {
				state.statusByName["topSeriesContents"] = "fulfilled";
				state.dataByName["topSeriesContents"] = action.payload;
			})
			.addCase(getTopSeriesContents.rejected, (state, action) => {
				state.statusByName["topSeriesContents"] = "rejected";
			});
		builder
			.addCase(getSerieLastMonthLikeAndFavorite.pending, (state, action) => {
				state.statusByName["serieLastMonthLikesAndFav"] = "pending";
			})
			.addCase(getSerieLastMonthLikeAndFavorite.fulfilled, (state, action) => {
				state.statusByName["serieLastMonthLikesAndFav"] = "fulfilled";
				state.dataByName["serieLastMonthLikesAndFav"] = action.payload;
			})
			.addCase(getSerieLastMonthLikeAndFavorite.rejected, (state, action) => {
				state.statusByName["serieLastMonthLikesAndFav"] = "rejected";
			});
		builder
			.addCase(getContentLastMonthLikeAndFavorite.pending, (state, action) => {
				state.statusByName["contentLastMonthLikesAndFav"] = "pending";
			})
			.addCase(getContentLastMonthLikeAndFavorite.fulfilled, (state, action) => {
				state.statusByName["contentLastMonthLikesAndFav"] = "fulfilled";
				state.dataByName["contentLastMonthLikesAndFav"] = action.payload;
			})
			.addCase(getContentLastMonthLikeAndFavorite.rejected, (state, action) => {
				state.statusByName["contentLastMonthLikesAndFav"] = "rejected";
			});
		builder
			.addCase(getSponsorAdViews.pending, (state, action) => {
				state.statusByName["sponsorAdViews"] = "pending";
			})
			.addCase(getSponsorAdViews.fulfilled, (state, action) => {
				state.statusByName["sponsorAdViews"] = "fulfilled";
				state.dataByName["sponsorAdViews"] = action.payload;
			})
			.addCase(getSponsorAdViews.rejected, (state, action) => {
				state.statusByName["sponsorAdViews"] = "rejected";
			});
		builder
			.addCase(getSponsorAdViewsByAds.pending, (state, action) => {
				state.statusByName["sponsorAdViewsByAds"] = "pending";
			})
			.addCase(getSponsorAdViewsByAds.fulfilled, (state, action) => {
				state.statusByName["sponsorAdViewsByAds"] = "fulfilled";
				state.dataByName["sponsorAdViewsByAds"] = action.payload;
			})
			.addCase(getSponsorAdViewsByAds.rejected, (state, action) => {
				state.statusByName["sponsorAdViewsByAds"] = "rejected";
			});
		builder
			.addCase(getPartnerSeriesIncomeAndProfit.pending, (state, action) => {
				state.statusByName["seriesIncome"] = "pending";
			})
			.addCase(getPartnerSeriesIncomeAndProfit.fulfilled, (state, action) => {
				state.statusByName["seriesIncome"] = "fulfilled";
				state.dataByName["seriesIncome"] = action.payload;
			})
			.addCase(getPartnerSeriesIncomeAndProfit.rejected, (state, action) => {
				state.statusByName["seriesIncome"] = "rejected";
			});
		builder
			.addCase(getInfluencerSerieIncomeAndProfit.pending, (state, action) => {
				state.statusByName["influencerIncome"] = "pending";
			})
			.addCase(getInfluencerSerieIncomeAndProfit.fulfilled, (state, action) => {
				state.statusByName["influencerIncome"] = "fulfilled";
				state.dataByName["influencerIncome"] = action.payload;
			})
			.addCase(getInfluencerSerieIncomeAndProfit.rejected, (state, action) => {
				state.statusByName["influencerIncome"] = "rejected";
			});
		builder
			.addCase(getInfluencerAndPartnerTransactions.pending, (state, action) => {
				state.loading = true;
				state.statusByName["latestTransactions"] = "pending";
			})
			.addCase(getInfluencerAndPartnerTransactions.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["latestTransactions"] = "fulfilled";
				state.dataByName["latestTransactions"] = action.payload;
			})
			.addCase(getInfluencerAndPartnerTransactions.rejected, (state, action) => {
				state.statusByName["latestTransactions"] = "rejected";
			});
		builder
			.addCase(getProfitCorporateTransactions.pending, (state, action) => {
				state.loading = true;
				state.statusByName["profitCorporate"] = "pending";
			})
			.addCase(getProfitCorporateTransactions.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["profitCorporate"] = "fulfilled";
				state.dataByName["profitCorporate"] = action.payload;
			})
			.addCase(getProfitCorporateTransactions.rejected, (state, action) => {
				state.statusByName["profitCorporate"] = "rejected";
			});
		builder
			.addCase(getSerieEarnings.pending, (state, action) => {
				state.loading = true;
				state.statusByName["serieContentEarning"] = "pending";
			})
			.addCase(getSerieEarnings.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["serieContentEarning"] = "fulfilled";
				state.dataByName["serieContentEarning"] = action?.payload?.result;
			})
			.addCase(getSerieEarnings.rejected, (state, action) => {
				state.statusByName["serieContentEarning"] = "rejected";
			});
		builder
			.addCase(getInfluencerProfitByContent.pending, (state, action) => {
				state.loading = true;
				state.statusByName["influencerProfitByContent"] = "pending";
			})
			.addCase(getInfluencerProfitByContent.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["influencerProfitByContent"] = "fulfilled";
				state.dataByName["influencerProfitByContent"] = action.payload;
			})
			.addCase(getInfluencerProfitByContent.rejected, (state, action) => {
				state.statusByName["influencerProfitByContent"] = "rejected";
			});
		builder
			.addCase(getUsersVerificationDataAction.pending, (state, action) => {
				state.loading = true;
				state.statusByName["usersVerificationData"] = "pending";
			})
			.addCase(getUsersVerificationDataAction.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["usersVerificationData"] = "fulfilled";
				state.dataByName["usersVerificationData"] = action.payload;
			})
			.addCase(getUsersVerificationDataAction.rejected, (state, action) => {
				state.statusByName["usersVerificationData"] = "rejected";
			});
		builder
			.addCase(getUsersActiveDataAction.pending, (state, action) => {
				state.loading = true;
				state.statusByName["usersActiveData"] = "pending";
			})
			.addCase(getUsersActiveDataAction.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["usersActiveData"] = "fulfilled";
				state.dataByName["usersActiveData"] = action.payload;
			})
			.addCase(getUsersActiveDataAction.rejected, (state, action) => {
				state.statusByName["usersActiveData"] = "rejected";
			});
		builder
			.addCase(getSponsorWorldViewsAction.pending, (state, action) => {
				state.loading = true;
				state.statusByName["sponsorWorldViews"] = "pending";
			})
			.addCase(getSponsorWorldViewsAction.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["sponsorWorldViews"] = "fulfilled";
				state.dataByName["sponsorWorldViews"] = action.payload;
			})
			.addCase(getSponsorWorldViewsAction.rejected, (state, action) => {
				state.statusByName["sponsorWorldViews"] = "rejected";
			});
		builder
			.addCase(getUsersDailyVerificationDataAction.pending, (state, action) => {
				state.loading = true;
				state.statusByName["usersDailyVerificationsData"] = "pending";
			})
			.addCase(getUsersDailyVerificationDataAction.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["usersDailyVerificationsData"] = "fulfilled";
				state.dataByName["usersDailyVerificationsData"] = action.payload;
			})
			.addCase(getUsersDailyVerificationDataAction.rejected, (state, action) => {
				state.statusByName["usersDailyVerificationsData"] = "rejected";
			});
		builder
			.addCase(getAllUsersDemographicData.pending, (state, action) => {
				state.loading = true;
				state.statusByName["usersDemographicData"] = "pending";
			})
			.addCase(getAllUsersDemographicData.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["usersDemographicData"] = "fulfilled";
				state.dataByName["usersDemographicData"] = action.payload;
			})
			.addCase(getAllUsersDemographicData.rejected, (state, action) => {
				state.statusByName["usersDemographicData"] = "rejected";
			});
		builder
			.addCase(getAppInstallations.pending, (state, action) => {
				state.loading = true;
				state.statusByName["appInstallationsData"] = "pending";
			})
			.addCase(getAppInstallations.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["appInstallationsData"] = "fulfilled";
				state.dataByName["appInstallationsData"] = action.payload;
			})
			.addCase(getAppInstallations.rejected, (state, action) => {
				state.statusByName["appInstallationsData"] = "rejected";
			});
		builder
			.addCase(getAppConsumptionByLocation.pending, (state, action) => {
				state.loading = true;
				state.statusByName["appConsumptionByLocation"] = "pending";
			})
			.addCase(getAppConsumptionByLocation.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["appConsumptionByLocation"] = "fulfilled";
				state.dataByName["appConsumptionByLocation"] = action.payload;
			})
			.addCase(getAppConsumptionByLocation.rejected, (state, action) => {
				state.statusByName["appConsumptionByLocation"] = "rejected";
			});
		builder
			.addCase(getSubscribersLocations.pending, (state, action) => {
				state.loading = true;
				state.statusByName["subscribersLocations"] = "pending";
			})
			.addCase(getSubscribersLocations.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["subscribersLocations"] = "fulfilled";
				state.dataByName["subscribersLocations"] = action.payload;
			})
			.addCase(getSubscribersLocations.rejected, (state, action) => {
				state.statusByName["subscribersLocations"] = "rejected";
			});
		builder
			.addCase(getRentalsLocations.pending, (state, action) => {
				state.loading = true;
				state.statusByName["rentalsLocations"] = "pending";
			})
			.addCase(getRentalsLocations.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["rentalsLocations"] = "fulfilled";
				state.dataByName["rentalsLocations"] = action.payload;
			})
			.addCase(getRentalsLocations.rejected, (state, action) => {
				state.statusByName["rentalsLocations"] = "rejected";
			});
		builder
			.addCase(getAssignedFreeSubscriptionsByUser.pending, (state, action) => {
				state.loading = true;
				state.statusByName["freeSubscriptionsByUsers"] = "pending";
			})
			.addCase(getAssignedFreeSubscriptionsByUser.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["freeSubscriptionsByUsers"] = "fulfilled";
				state.dataByName["freeSubscriptionsByUsers"] = action.payload;
			})
			.addCase(getAssignedFreeSubscriptionsByUser.rejected, (state, action) => {
				state.statusByName["freeSubscriptionsByUsers"] = "rejected";
			});
		builder
			.addCase(getReproductionTime.pending, (state, action) => {
				state.loading = true;
				state.statusByName["getReproductionTime"] = "pending";
			})
			.addCase(getReproductionTime.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["getReproductionTime"] = "fulfilled";
				state.dataByName["getReproductionTime"] = action.payload;
			})
			.addCase(getReproductionTime.rejected, (state, action) => {
				state.statusByName["getReproductionTime"] = "rejected";
			});
		builder
			.addCase(getMuxDataViewsPercentsAction.pending, (state, action) => {
				state.loading = true;
				state.statusByName["getMuxDataViewsPercents"] = "pending";
			})
			.addCase(getMuxDataViewsPercentsAction.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["getMuxDataViewsPercents"] = "fulfilled";
				state.dataByName["getMuxDataViewsPercents"] = action.payload;
			})
			.addCase(getMuxDataViewsPercentsAction.rejected, (state, action) => {
				state.statusByName["getMuxDataViewsPercents"] = "rejected";
			});
		builder
			.addCase(listPlatformCostsAction.pending, (state, action) => {
				state.loading = true;
				state.statusByName["listPlatformCosts"] = "pending";
			})
			.addCase(listPlatformCostsAction.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["listPlatformCosts"] = "fulfilled";
				state.dataByName["listPlatformCosts"] = action.payload;
			})
			.addCase(listPlatformCostsAction.rejected, (state, action) => {
				state.statusByName["listPlatformCosts"] = "rejected";
			});
		builder
			.addCase(getCostAndEarningsDataAction.pending, (state, action) => {
				state.loading = true;
				state.statusByName["getCostAndEarnings"] = "pending";
			})
			.addCase(getCostAndEarningsDataAction.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["getCostAndEarnings"] = "fulfilled";
				state.dataByName["getCostAndEarnings"] = action.payload;
			})
			.addCase(getCostAndEarningsDataAction.rejected, (state, action) => {
				state.statusByName["getCostAndEarnings"] = "rejected";
			});
		builder
			.addCase(getPartnersSeriesProfitByRent.pending, (state, action) => {
				state.loading = true;
				state.statusByName["rentalsIncomeEarning"] = "pending";
			})
			.addCase(getPartnersSeriesProfitByRent.fulfilled, (state, action) => {
				state.loading = false;
				state.statusByName["rentalsIncomeEarning"] = "fulfilled";
				state.dataByName["rentalsIncomeEarning"] = action.payload?.data;
			})
			.addCase(getPartnersSeriesProfitByRent.rejected, (state, action) => {
				state.statusByName["rentalsIncomeEarning"] = "rejected";
			});
	},
});

export const dashboardActions = {
	...dashboardSlice.actions,
	getGeneralSeriesIncomeAndProfit,
	getPartnersSeriesProfit: getPartnersSeriesProfit,
	getSerieLastMonthLikeAndFavorite,
	getContentLastMonthLikeAndFavorite,
	getTopSeriesContents,
	getPartnersSeriesProfitByRent,
	getTopSeries,
	getSponsorAdViews,
	getSponsorAdViewsByAds,
	getPartnerSeriesIncomeAndProfit,
	getInfluencerSerieIncomeAndProfit,
	getInfluencerAndPartnerTransactions,
	getProfitCorporateTransactions,
	getSerieEarnings,
	getInfluencerProfitByContent,
	getUsersVerificationDataAction,
	getUsersActiveDataAction,
	getSponsorWorldViewsAction,
	getUsersDailyVerificationDataAction,
	getAllUsersDemographicData,
	getAppInstallations,
	getAppConsumptionByLocation,
	getSubscribersLocations,
	getRentalsLocations,
	getAssignedFreeSubscriptionsByUser,
	getReproductionTime,
	getMuxDataViewsPercentsAction,
	listPlatformCostsAction,
	editPlatformCostDataAction,
	editCostConceptDataAction,
	listCostConceptDataAction,
	insertCostConceptDataAction,
	insertPlatformCostDataAction,
	getCostAndEarningsDataAction,
};

export const selectedPartnerSerieSelector = createSelector(
	(state: RootState) => state.user.userInfo?.get_partner_series,
	(state: RootState) => state.dashboard.selectedSerieId,
	(partnerSeries, selectedSerieId) => partnerSeries?.find(el => el.serieid === selectedSerieId),
);

export const selectedPartnerSerieContentSelector = createSelector(
	(state: RootState) => state.dashboard.dataByName["topSeriesContents"],
	(state: RootState) => state.dashboard.selectedContentId,
	(serieContents, selectedContentId) => serieContents?.find(el => el.contentid === selectedContentId),
);

export const selectedPartnerSeries = createSelector(
	(state: RootState) => state.user.userInfo?.get_partner_series,
	(state: RootState) => state.dashboard.dataByName["topSeries"],
	(partnerSeries, topSeries) =>
		partnerSeries
			?.filter(el => el.active)
			.map(el => {
				const serieData = topSeries?.find(serie => serie.seriesid === el.serieid);

				return {...el, ...serieData};
			}),
);

export const selectTotalizedStatistic = createSelector(
	(state: RootState) => state.user.userInfo?.get_partner_series,
	(state: RootState) => state.dashboard.dataByName["seriesIncome"],
	(state: RootState) => state.dashboard.dataByName["topSeries"],
	(partnerSeries, serieIncomeMap, topSeries) => {
		const seriesByIncome: {serieId: string; title: string; amount: number; img?: string}[] = [];
		let totalSeriesIncome = 0;
		let netTotalIncome = 0;
		let totalIncomeLastMonth = 0;
		let totalPartnerIncome = 0;

		for (const serie in serieIncomeMap) {
			const element = serieIncomeMap[serie];
			const partnerSerieData = partnerSeries?.find(el => el.serieid === serie);
			const seriesData = topSeries?.find(el => el.seriesid === serie);

			if (!partnerSerieData) continue;

			totalSeriesIncome += element.total_profit;
			totalIncomeLastMonth += element.range_profit;
			const elNetTotalIncome = element.total_profit - (partnerSerieData?.production_cost ?? 0);
			netTotalIncome += elNetTotalIncome;
			totalPartnerIncome += elNetTotalIncome > 0 ? elNetTotalIncome * ((partnerSerieData?.profit ?? 0) / 100) : 0;

			seriesByIncome.push({
				serieId: partnerSerieData?.serieid,
				title: partnerSerieData?.title,
				amount: elNetTotalIncome > 0 ? elNetTotalIncome * ((partnerSerieData?.profit ?? 0) / 100) : 0,
				img: seriesData?.img_thumbnail,
			});
		}

		return {totalSeriesIncome, totalIncomeLastMonth, netTotalIncome, totalPartnerIncome, seriesByIncome};
	},
);

export const selectedInfluencerSerieContentSelector = createSelector(
	(state: RootState) => state.dashboard.dataByName["topSeriesContents"],
	(state: RootState) => state.dashboard.selectedContentId,
	(serieContents, selectedContentId) => serieContents?.find(el => el.contentid === selectedContentId),
);

export const selectedInfluencerSeries = createSelector(
	(state: RootState) => state.user.userInfo?.get_influencer_data?.influencer_series,
	(state: RootState) => state.dashboard.dataByName["topSeries"],
	(influencerSeries, topSeries) =>
		influencerSeries
			?.filter(el => el.active)
			.map(el => {
				const serieData = topSeries?.find(serie => serie.seriesid === el.serie_id);
				return {...el, ...serieData};
			}),
);

export const selectedInfluencerSerieSelector = createSelector(
	selectedInfluencerSeries,
	(state: RootState) => state.dashboard.selectedSerieId,
	(influencerSeries, selectedSerieId) => influencerSeries?.find(el => el.serie_id === selectedSerieId),
);

export const selectedPartnerRentalsLatestTransactions = createSelector(
	(state: RootState) => state.dashboard.dataByName["latestTransactions"],
	transactions => transactions?.filter(item => item?.rental_type !== undefined || item.membership_type !== undefined),
);

export const selectedFirstInfluencerReferredUsersTransactions = createSelector(
	(state: RootState) => state.dashboard.dataByName["latestTransactions"],
	transactions => {
		if (!transactions?.length) return [];

		const uniqueData = transactions.reduce((acc: GetInfluencerAndPartnerTransactionsResponse[], curr) => {
			// TODO: Change this to a better way to filter the data(like referred user id or something else)
			const duplicatedList = transactions.filter(item => item.userid === curr.userid);
			if (!duplicatedList.length) return [...acc, curr];
			if (acc.some(item => item.userid === curr.userid)) return acc;
			const sortedDuplicateds = duplicatedList.sort((a, b) => a.created_at.localeCompare(b.created_at));

			return [...acc, sortedDuplicateds[0]];
		}, []);

		return uniqueData.sort((a, b) => b.created_at.localeCompare(a.created_at));
	},
);

export default dashboardSlice;
